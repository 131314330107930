
@media (min-width: 992px) {
    .side-header #header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 260px;
        height: 100%;
        height: calc(100vh);
        background-color: #FFF;
        border: none;
        border-right: 1px solid #EEE;
        overflow: hidden;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.065);
    }
    
    .side-header #header-wrap {
        width: 300px;
        height: 100%;
        overflow: auto;
        padding-right: 40px;
    }
    
    .side-header #header .container {
        width: 260px !important;
        padding: 0 30px !important;
        margin: 0 !important;
        
    }
    
    .side-header #logo {
        float: none;
        margin: 60px 0 40px;
    }
    
    .side-header #logo img { height: auto !important; }
    
    .side-header #logo:not(.nobottomborder):after,
    .side-header #primary-menu:not(.nobottomborder):after {
        display: block;
        content: '';
        width: 40%;
        border-bottom: 1px solid #E5E5E5;
    }
    
    .side-header .center #logo:after { margin: 0 auto; }
    
    .side-header #primary-menu { margin-bottom: 30px; }
    
    .side-header #primary-menu:not(.nobottomborder):after { margin-top: 40px; }
    
    .side-header #primary-menu,
    .side-header #primary-menu ul {
        float: none;
        height: auto;
        margin-left: 0;
    }
    
    .side-header #primary-menu ul li {
        float: none;
        margin: 15px 0 0;
    }
    
    .side-header #primary-menu ul li:first-child { margin-top: 0; }
    
    .side-header #primary-menu ul li a {
        height: 25px;
        line-height: 25px;
        padding: 0;
        text-transform: uppercase;
    }
    
    .side-header #primary-menu ul li i { line-height: 24px; }
    
    .side-header #primary-menu ul ul {
        position: relative;
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        border: none;
        height: auto;
        z-index: 1;
        top: 0;
        left: 0;
        margin: 5px 0;
        padding-left: 12px;
    }
    
    .side-header #primary-menu ul ul ul {
        top: 0 !important;
        left: 0;
    }
    
    .side-header #primary-menu ul ul.menu-pos-invert {
        left: 0;
        right: auto;
    }
    
    .side-header #primary-menu ul ul ul.menu-pos-invert { right: auto; }
    
    .side-header #primary-menu ul ul li {
        margin: 0;
        border: 0;
    }
    
    .side-header #primary-menu ul ul li:first-child { border-top: 0; }
    
    .side-header #primary-menu ul ul a {
        font-size: 12px;
        height: auto !important;
        line-height: 24px !important;
        padding: 2px 0 !important;
    }
    
    .side-header #primary-menu ul ul li:hover > a {
        background-color: transparent !important;
        color: #444 !important;
    }
    
    .side-header #primary-menu ul > li.sub-menu > a,
    .side-header #primary-menu ul > li.sub-menu:hover > a { background-image: none !important; }
    
    .side-header #primary-menu ul > li.sub-menu > a:after {
        position: absolute;
        content: '\e7a5';
        top: 1px;
        right: 0;
        -webkit-transition: transform .2s linear;
        -o-transition: transform .2s linear;
        transition: transform .2s linear;
    }
    
    .side-header #primary-menu ul > li.sub-menu:hover > a:after {
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    
    .side-header:not(.open-header) #wrapper {
        margin: 0 0 0 260px !important;
        width: auto !important;
    }
    
    .side-header:not(.open-header) #wrapper .container {
        width: auto;
        max-width: none;
        margin: 0 40px;
    }
}
.label{
    text-transform: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Jost", sans-serif;
}
.statistics {
  /* background-color: #f5f5f5; */
  padding-top: 20px;
}
.statistics .totalGraph {
  margin-bottom: 0px;
}
.statistics .totalGraph h3 {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #00365b;
}
.w-50 {
  width: 50%;
}
.statistics .card {
  /* box-shadow: 0px 0px 4px 0px grey; */
  margin-top: 10px;
  margin-bottom: 20px;
}
.statistics .card .card-body {
  text-align: center;
  background-color: #f7eddb;
}
.statistics .card .card-body h3 {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #00365b;
  
}
.heading2 {
  padding: 25px;
  font: size 50px;
  margin: 0 0 0px 0;
}
.missionParagraph {
  background-color: #f7eddb;
  padding: 50px 10px;
}
.heading-block h1 {
  color: #00365b;
}
.h5 {
  color: #00365b;
  font-size: 17px;
}
.feature-box.media-box1 {
  padding: 0;
  min-height: 383px;
}
.priorityBox {
  background-color: white;
  box-shadow: 0px 0px 2px 0px grey;
  min-height: 350px;
}
.regno {
  text-align: center;
  color: white;
  font-size: 25px;
}
.event {
  padding-bottom: 50px;
}
.facts {
  background-color: #f5f5f5;
}
.queation {
  max-width: 20%;
}
.Emp {
  background-color: #f5f5f5;
}
.span {
  line-height: 1.5;
  color: #00365b;
  font-family: "Lato", sans-serif;
  font-size: 1rem;
}
.h2 {
  font-size: 30px;
}
.ppri {
  font-size: 0.875rem;
  margin: 8px 0 0 0;
  font-family: "Lato", sans-serif;
  line-height: 1.5;
}
.footer {
  text-align: center;
}
.accormain {
  margin-top: 34px;
}
.accordion {
  margin-bottom: 1px;
}
.accordionh {
  border: "1px solid rgba(0, 0, 0, 0.05)";
  background-color: #efefef;
  margin: 0px 0px 1px;
}
.accordionh button {
  border: none;
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #444;
  text-align: top;
  padding-left: 3px;
}
.accordionb {
  padding-left: 3px;
}
.arrowicon {
  float: left;
}
.background {
  background-color: #f5f5f5;
}
.backgroundi {
  background-color: white;
  box-shadow: 0px 0px 2px 0px black;
}
.create a {
  color: #555;
}
.label {
  text-transform: none;
}
.pcard {
  box-shadow: 2px 2px 10px #dadada;
}
.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
  color: black;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-counter.primary {
  background-color: #007bff;
  color: #fff;
}

.card-counter.danger {
  background-color: #ef5350;
  color: #fff;
}

.card-counter.success {
  background-color: #66bb6a;
  color: #fff;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 4em;
  opacity: 0.2;
  margin-top: -18px;
}

.card-counter .count-numbers {
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 32px;
  display: block;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  top: 73px;
  /* font-style: italic; */
  text-transform: capitalize;
  display: block;
  font-size: 18px;
  color: black;
}
.top-2{
	top: -2px !important;
}
@media only screen and (min-device-width: 200px) and (max-device-width: 768px) {
  .legend {
    display: none;
  }
}

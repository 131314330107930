/* Form */
:root {
  --button-bg-color: #00365b;
  --button-text-color: #e0e0e0;
  --button-border-color: transparent;
}
.error {
  color: #ff0000a6;
  font-size: 80%;
}

.flex {
  display: flex;
}
.flat-btn {
  border: none;
  outline: none;
  background: none;
}

.imageUpload {
  position: relative;
  border: solid 1px #d1d1d199;
}
.imageUpload button {
  position: absolute;
  top: 43%;
  background-color: #ffffffe0;
  box-shadow: 1px 1px 3px -2px;
  border: none;
  left: 45%;
}
.imageUpload .loader {
  position: absolute;
  top: 41%;
  border: none;
  left: 39%;
}
.navDropdown {
  text-decoration: none;
  color: #444;
  font-weight: bold;
  font-size: 13px;
  padding: 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
}
.navDropdown a {
  text-decoration: none;
  color: #444;
  padding-left: 7px;
}
.button {
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border: 1px solid var(--button-border-color);
}
.pricing-box .pricing-title img {
  background-color: transparent;
}

@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");





.menu-link ul li {
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  /* font-size: 1.8rem; */
}

.menu-link ul li a {
  text-transform: capitalize;
  color: rgba(0, 0, 3, 0.8);
  transition: 0.5s;
  float: right;
}
.menu-link ul li:hover > a {
  transform-origin: left;
  color: #1abc9c;
  text-decoration: underline;
  transition: 0.5s;
}



 .hamburger-menu {
  display: none;

}
.hamburger-menu a{
 float: right;
 margin-top: 30px;
}
.hamburger-menu a{
  color: black;
}


/* responsive css style  */
@media (max-width: 1084px) {
  
  .main-nav {
    
    height: 7rem;
    grid-template-columns: 0rem 2fr 3fr 2rem 2rem;
  }
  

  .menu-link {
    
  }

 
 

  .mobile-menu-link {
   
    /* position: relative; */
    /* z-index: 99; */
  }

  .mobile-menu-link {
    margin-top: 16px;
    height: 20rem;
    display: grid;
    align-items: center;
    
    transition: all 2s linear;
    transform-origin: top;
    }

  /* .mobile-menu-link #primary-menu ul {
    height: 20rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: start ;
    margin-top: 100px;
  } */

  .mobile-menu-link ul li:first-child {
    transition-delay: 0.2s;
  }

   .hamburger-menu {
    display: block;
    font-size: 1rem;
  }
}

@media (max-width: 750px) {

  .main-nav {
    height: 6rem;
    
  }
  #primary-menu.menu-link{
    display: none;
  }

  .hamburger-menu {
    display: block;
    font-size: 1rem;
  }
}

@media (max-width: 520px) {
  .main-nav {
    height: 6rem;
    
  }
  #primary-menu.menu-link{
    display: none;
  }


.hamburger-menu {
    display: block;
    font-size: 1rem;
  }
}

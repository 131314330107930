.gmap{
    height: 410px; 
    position: relative; 
    overflow: hidden;
}
.gmapi{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px; 
    left: 0px; 
    background-color: rgb(229, 227, 223);
}
.gm-err-icon img{
    user-select: none;
}